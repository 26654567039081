/* main.css */
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');

/* Define CSS variables */
:root {
--primary-color: #0e486d;
--secondary-color: #0077c8;
--success-color: #198754;
--info-color: #0077c8;
--warning-color: #ffc107;
--danger-color: #dc3545;
--light-color: #b8d1df;
--lighter-color: #b8d1df;
--dark-color: #0e486d;
--tertiary-color: rgba(133, 162, 181);
--highlight-color: #ffbd59;
--card-color: #1f95d1;
}

body {
    font-family: 'League Spartan', 'Times New Roman', Times, serif !important;
    overflow-x: hidden;
}

/* Background Elements */

.bg-elem-container{
    position: relative;
    
    width: 100vw;
    min-height: 100vh;


}

.bg-elem {
    position: absolute;
    z-index: -1;
}

.bg-elem-3 {
    transform: rotate(-50deg) ;
    width: 80vh;
    left: -30vh;
    top: -15vh;
}

.bg-elem-3-1 {
    transform: rotate(80deg);
    width: 50vw;
    right: -20vw;
    bottom: 0;

}

.bg-elem-6 {
    transform: rotate(160deg);
    width: 40vh;
    bottom: -15vh;
    left: -20vh;
    z-index: -1;
}


.bg-about {
    background-color: var(--light-color);
    margin-top: 10vh;
    
}

.wave1 {
    width: 100vw;
    top: -30vw;
    z-index: 0;
}

.wave2 {
    width: 100vw;
    bottom: -30vw;
}

@media screen and (max-width: 700px) {

    .bg-elem-3 {
        transform: rotate(-50deg) ;
        width: 80vh;

        left: -40vh;
        top: -20vh;
    }

    
    .bg-elem-3-1 {
        transform: rotate(-110deg);
        width: 50vh;
        right: -20vh;
        bottom: -10vh;
    }
}


/* Navbar */
.header .navbar-brand {
    font-weight: 700;
    font-size: xx-large;
}

.header a {
    color: var(--light-color);
    font-weight: 500;
    font-size: x-large;
}

.header a.active {
    color: var(--light-color) !important;
}

.name-brand {
    height: 50px;
    width: auto;
}


/* Home */

#home {
    width: 100%;
    min-height: 90vh;
    max-height: 100vh;

    display: block;
    position: relative;
    overflow: hidden;
    padding: 0;
}

.home-img {
    z-index: 0;
    min-height: 90vh;
    width: 100%;
    object-fit: cover;
}

.img-overlay {
    position: relative;
}

.img-overlay::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--secondary-color); /*Semi-transparent blue color */

    opacity: 0.2;

  }

  
.home-box {
    position: absolute;
    
    top: 50%;
    left: 50%;

}

.home-box h1 {
    font-family: "Bonheur Royale", cursive;
    color: var(--light-color);
    font-size: 5.5vw;
    font-weight: 800;   
}

.home-box h2 {
    color: var(--primary-color);
    font-weight: 700;
    font-size: 3vw;
    letter-spacing: 0.5vw;
    /* text-align: right;
    margin-right: 15vw; */
}

.home-box .cta-box {
    margin: auto;
}

.home-button {
    max-width: 250px;    

    font-weight: 700 !important;
    border: none !important;
    border-radius: 25px !important;
    
    background-color: var(--lighter-color);
    box-shadow: -2px -5px 0px 0px var(--primary-color);

    transition: transform 0.25s ease !important;
}

.home-button:hover {
    transform: scale(1.05);
}

.home-button div {
    display: flex;
    flex: column;
    align-items: center !important;

}

.home-button div > div { /* background of the icon */
    padding: 5px;
    border-radius: 50%;

    background-color: var(--primary-color);
}

.home-button p {
    margin: auto;
    line-height: 20px;
}

.home-button img {
    max-height: 25px;
    width: auto;  
}

@media screen and (max-width: 700px){
    .home-box h1 {
        font-size: 8vh;
        margin-bottom: 10vh;
    }
    .home-box h2 {
        font-size: 8vw;
        font-weight: 700;
        margin: 0 2vw;
    }
}


/* Testimonial Carousel */
.testimonial-carousel {
    margin: auto;
}

.testimonial-slide {
    min-height: 20rem;
    height: auto;
    max-height: 600px;

    margin: auto;
}


/* Professional Process */
.process-section {
    position: relative;
    overflow: hidden;
}

.process-section h2 {
    font-size: xxx-large;
}

.process-step {
    margin: auto;
    padding: 50px 0;

    /* text-align: justify; */
    
}

/* Buy Sell Section */

.buy-sell-section {
    height: auto;

    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.buy-sell-section h2 {
    position: absolute;

    top: 0;
    right: 0;

    font-size: 6rem;
    font-weight: 700;
    letter-spacing: 2px;
    color: var(--highlight-color);
}

.flowchart {
    margin-top: 15vh;

    height: 120vh;
    width: auto;
}

.card-container {
    margin-top: 20vh;

}

.info-card {
    width: 50vh;
    height: 50vh;

    background-color: var(--light-color);
    border-radius: 30px;
    box-shadow: -10px 10px 0px 0px var(--secondary-color);

    display: flex;
    flex-direction: row;
    align-items: end;
    
    transition: box-shadow .25s;
}

.info-card:hover {
    box-shadow: -15px 15px 0px 0px var(--secondary-color);
}

.info-card-back {
    background-color: var(--secondary-color);
    border-radius: 30px;
    box-shadow: -10px 10px 0px 0px var(--light-color);

    align-items: start;
    transition: box-shadow .25s;
}

.info-card-back:hover {
    box-shadow: -15px 15px 0px 0px var(--light-color);
}

.info-card h3 {
    font-weight: 800;
    letter-spacing: 5px;
    color: var(--primary-color);
}

.info-card-back h3 {
    color: var(--light-color);

    font-size: 30px;
    margin-top: 3vh;
    margin-left: 3vh;

}

.info-card-buy h3{
    font-size: 7cqw;  
    margin-left: 3vh;
}

.info-card-sell {
    flex-direction: column;
    align-items: end;
}

.info-card-sell  h3{
    font-size: 5cqw;  
    margin-top: 2vh;
    margin-right: 3vh;
}


@media screen and (max-width: 1000px) {
    .services-container {
        min-height: 80vh !important;
    }
    
    .buy-sell-section {
        flex-direction: column;

        height: auto;
        margin: 10vh 0;
    }

    .buy-sell-section h2 {
        position: relative;

        margin: auto;
        text-align: center;
        font-size: 3rem;
    }

    .info-card {
        margin: auto;
    }

    .card-container {
        margin: 5vh 0;
    }

    .info-card-buy h3 {
        font-size: 60px
    }

    .info-card-sell h3 {
        font-size: 40px
    }    

    .flowchart {
        margin-top: 5vh;
        align-self:baseline;

        max-width: 80vw;
        height: auto;
    }
}

@media screen and (max-width: 600px) {
    .info-card {
        width: 60vw;
        height: 60vw;
    }
}

/* Listing Section */

.featured-listings h2 {
    text-align: left;
    color: var(--highlight-color);

    font-size: 6rem;
    font-weight: 700;

    position: relative;
    z-index: 999;
}

.featured-listings-carousel {

    display: flex;

    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

    margin-top: -5vh;
    z-index: 2;

    position: relative;
    
}

.slick-slide > div {
    display: flex;
    justify-content: center;
    /* margin-right: auto; */
}

.slick-next, .slick-prev {
    transform: scale(2) !important;
}

.featured-listings-carousel .slick-next::before {
    color: var(--primary-color);
}

.featured-listings-carousel .slick-prev::before {
    color: var(--primary-color);
}

.listing-card {
    margin: auto;
    max-width: auto;
    max-height: fit-content;

    border-radius: 30px !important;
    box-shadow: 10px 10px 0px 0px #38b6ff;
    
    transition: transform 0.25s;
}
.listing-card-container {
    margin: 5vh 2vw;
    max-width: 20vw;
}
.listing-card-container:hover .listing-card {
    transform: translateY(-20px);
}

.listing-card-img-container {
    max-width: 100%;
    padding: 10%;

}

.listing-card-img {
    border: 2px solid black;
}

.listing-card-details {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    font-size: larger;
    margin-top: 10%;
}

.listing-card-details span {
    margin-bottom: 0;
}

.listing-card-details-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.listing-card-button {
    margin-top: 5%;
    justify-self: center;
    
    border-radius: 30px !important;
    font-weight: 600 !important;
}

@media screen and (max-width: 1000px) {
    .featured-listings h2 {
        text-align: center;
        font-size: 80px;
    }

    .listing-card-container {
        min-width: 40vw;
    }

    .featured-listings-carousel {
        margin-top: 5vh;
        margin-left: 10vw;
        margin-right: 10vw;
    }
}

@media screen and (max-width: 650px) {
    .featured-listings h2 {
        font-size: 50px;
    }
    .listing-card-container {
        min-width: 60vw;
    }

}

        

/* About Section */

.about-section {
    letter-spacing: 1px;
    /* margin: 5vh; */

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-image: "../images/about-back.svg";
    
    z-index: 1;
}

.about-text {
    padding-left: 5vw;
    z-index: 1;
}

.about-header h2{
    font-weight: 800;
    font-size: 70px;
    margin-top: 10vh;
}

.about-header h3 {
    font-weight: 600;
    letter-spacing: 1.5px;
}

.about-description h4 {
    font-weight: 400;
}

.background-details {
    max-width: 40vw;
    min-width: 400px;
}

.about-graphic {
    margin-right: 0;
    padding-right: 5vw;

    justify-self: right;
    z-index: 1;
}

.about-graphic img {
    height: 70vh;
    width: auto;
    max-height: 1000px;  

    margin-top: 10vh;
}

@media screen and (max-width: 800px){
    .about-section {
        flex-direction: column;
    }

    .about-text {
        padding-left: 0;
    }

    .about-header {
        text-align: center;
    }

    .about-graphic img {
        height: 50vh;
    }

    .background-details {
        max-width: 80vw;
        text-align: center;
        margin: 0 auto;
    }
}


/*
    because of canvas, there is horizontal scrolling. need to fix. (check padding/margin on the right)
*/
.about-canvas {
    height: auto;
    width: 100vw;
    /* padding: 5vh 0; */

    /* display: block; */
    position: relative;

    overflow: hidden;
}

.about-back {
    position:absolute;

    top: -5rem;
    left: 5vw;


    width: 62vw;
    height:auto;
    display:fixed;
    object-fit:cover;
    transform: rotate(-20deg) translate(-0%, -5%);

    z-index: 0;
}

/* Contact Section */

.contact-container {
    height: 80vh;
    width: 100vw;
    margin-top: 30vh;
    margin-bottom: 10vh;
    
    display: flex;
}

.contact-box {
    height: 60vh;
    width: 40vw;
    max-width: 1000px;
    max-height: 1000px;

    margin: auto;

    display: flex;
    flex-direction: column;
    /* align-items: center; */

    /* background-image: url("../images/contact-back.svg");
    background-repeat: no-repeat;
    background-size: 40vh;
    background-position: center; */
    
}

.contact-box img {
    position: absolute;
    transform: rotate(0deg);
    
    margin-top: -7vh;
    margin-left: 0;
    
    width: 40%;
    height: auto;
    max-width: 1000px;
    
    z-index: -1;
}
.contact-box h2 {
    margin: auto;
    margin-top: 0;
    
    color: var(--highlight-color);
    font-size: 6rem;
    font-weight: 600;
    letter-spacing: 2px;
}


/* Remove all the .form-control and span colors if you want to use default */
/* .contact-form .form-control {
    background-color: var(--secondary-color);
    color: white;
}
.contact-form .form-control:focus {
    background-color: var(--secondary-color);
    color: white;

    box-shadow: none;
    border-color: white;
}

.contact-form span {
    background-color: var(--secondary-color);
    color: white;

    min-width: 7rem;
} */

.contact-form .contact-message {
    min-height: 20vh;
}

.contact-form .submit-button {
    width: 100%;    
    background-color: var(--secondary-color);
}

.contact-form .submit-button:hover {
    background-color: var(--highlight-color);
}

@media screen and (max-width: 1100px) {
    .contact-box {
        width: 80vw;
        display: flex;
        align-items: center;
    }
    
    .contact-form {
        width: 80%;
    }
    .contact-box img {
        width: 40vw;

        margin: auto;
        transform: rotate(0deg);
    }


}

@media screen and (max-width: 500px) {
    .contact-container {
        margin-top: 10vh;
    }
    
    .contact-box h2 {
        margin-bottom: 5vh;
        font-size: 50px;
    }

    .contact-form {
        width: 95%;
    }

    .contact-form span {
        /* min-width: 25vw; */
    }

    .contact-box img {
        width: 100vw;
    }
}

@media screen and (max-height: 500px) {
    .contact-container {
        height: 100vh;
    }
    
}




/* Footer */

footer {
    background-color: var(--light-color);
}

.footer-box {
    display: flex;
    flex-direction: row;
}

.footer-icon img {
    height: 15px;
    width: auto;
    margin: 0;

    filter: brightness(0) saturate(100%) invert(21%) sepia(11%) saturate(7169%) hue-rotate(175deg) brightness(92%) contrast(91%);
}

.footer-icon p {
    text-align: left;
    margin: 0;
    margin-left: 10px;
}

.footer-logos {
    overflow: hidden;
    padding: auto;

    /* width: 100rem; */
}

.footer-logos img {
    max-width: 5rem;
    height: auto;
    padding-right: auto;
    object-fit: contain;

    filter: brightness(0) saturate(100%) invert(21%) sepia(11%) saturate(7169%) hue-rotate(175deg) brightness(92%) contrast(91%);
}
.footer-logos .logo-2 {
    max-width: 10rem;
    height: auto
}

.footer-logos .logo-3 {
    filter: none;
}

.footer-nav {
    font-weight: 600;
    font-size: larger;
    text-align: right;
}

.footer a.active {
    color: var(--dark-color) !important;
}

@media screen and (max-width: 600px){
    
    .footer-logos {
        justify-content: center;
    }
    .footer-logos img {
        max-width: 20%
    }

    .footer-logos .logo-2 {
        max-width: 40%;
    }

    .footer-box {
        flex-direction: column-reverse;
    }

    .footer-nav {
        text-align: left;
        margin-bottom: 30px;
    }
}



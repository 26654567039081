// Import Bootstrap functions first
@import "~bootstrap/scss/functions";

// Override Bootstrap color variables
$theme-colors: (
  "primary": #0e486d,
  "secondary": #0077c8,
  "success": #198754,
  "info": #0077c8,
  "warning": #ffc107,
  "danger": #dc3545,
  "light": #b8cbca,
  "lighter": #b8d1df,
  "dark": #0e486d,
  "highlight": #ffbd59,
  "card": #1f95d1,

  // Overwrite gray colors
  "gray-100": #f8f9fa,  // lighter gray
  "gray-200": #e9ecef,
  "gray-300": #dee2e6,
  "gray-400": #ced4da,
  "gray-500": #adb5bd,  // default gray
  "gray-600": #868e96,
  "gray-700": #495057,
  "gray-800": #343a40,
  "gray-900": #212529  // darker gray
);

// Override black and very dark shades in Bootstrap
$black: #212529; // very dark gray
$black-50: #495057; // dark gray



// Import the rest of Bootstrap
@import "~/node_modules/bootstrap/scss/bootstrap";
